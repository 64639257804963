import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/common/hero';
import heroImg from '../assets/img/e-2-visa-attorney-services.jpg';
import CtaDeck from '../components/common/cta-deck';
import FeaturedIn from '../components/common/featured-in';
import Testimonials from '../components/common/testimonials';
import SuccessStoriesDeck from '../components/common/Success-stories-deck';
import PilarNavigation from '../components/pilar/pilar-navigation';
import { INVESTOR_VISA_IMMIGRATION_TESTIMONIALS } from '../data/investor-visa-usa-immigration/testimonials';
import ReviewDeck from '../components/common/review-deck';
import { AB_TESTING } from '../data/site-data';
import AbTestingDeck from '../components/ab-testing/ab-testing-deck';
import Reviewer from '../components/common/reviewer';
import Signature from '../components/common/signature';
import { prependStaticURL } from '../utils';

const headMeta = {
  title: 'E-2 Investor Visa USA | Requirements & Application | Pandev Law',
  description:
    'Searching for a trusted and experienced E-2 Investor Visa immigration lawyer? Pandev Law is here to help you. Talk to us now!',
  keywords:
    'investor immigration,investor visa USA,entrepreneur visa USA,US green card by investment',
};

const schema = `[
  {
    "@context": "http://schema.org",
    "@type": "Product",
    "name": "E-2 Investor Visa USA | Requirements & Application | Pandev Law",
    "description": "Searching for a trusted and experienced E-2 Investor Visa immigration lawyer? Pandev Law is here to help you. Talk to us now!",
    "url": "https://www.pandevlaw.com/e-2-visa",
    "image": "https://pandevlaw.com/img/e-2-visa-attorney-services.jpg",
    "brand": "Pandev Law",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "bestRating": "5",
      "worstRating": "0",
      "ratingCount": "246"
    },
    "sameAs": [
      "https://en-gb.facebook.com/pandevlaw/"
    ]
  },
  {
  "@context": "https://schema.org",
  "@type": "Article",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://www.pandevlaw.com/e-2-visa/"
  },
  "headline": "E-2 Visa USA Services",
  "description": ${JSON.stringify(headMeta.description)},
  "image": ${prependStaticURL(heroImg)},  
  "author": {
    "@type": "Person",
    "name": "Adrian Pandev",
    "url": "https://www.pandevlaw.com/about/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Pandev Law",
    "logo": {
      "@type": "ImageObject",
      "url": "https://www.pandevlaw.com/static/13437dd4aac9a31bf6176b69797f47be/61fd1/logo.webp"
    }
  },
  "datePublished": ""
}]`;

function E2Visa({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema}>
      <Hero title="E-2 Visa USA Services" img={heroImg}>
        <p className="text-lead mb-5 mb-md-6">
          I am an experienced E-2 visa lawyer who has helped numerous foreign nationals
          qualify for the E-2 visa USA program. I combine my knowledge and experience in
          both business and immigration law to guide you through the process of obtaining
          a E-2 investor visa, advise you on how to meet the treaty{' '}
          <Link to="/investor-visa-usa-immigration/">investor visa</Link> requirements,
          and assist you with your visa application. I help you design and execute your
          personalized US immigration strategy. You can find more information about the
          E-2 visa USA program, requirements, processing times, and my E-2 visa lawyer
          services below:
        </p>
      </Hero>

      <AbTestingDeck
        position="top"
        title="E-2 Visa Testimonials"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />

      <div className="section pt-5 pb-5 pt-md-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/what-is-e-2-visa.jpg"
                alt="What is an E-2 visa?"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <Reviewer />
              <h2 className="text-headline mb-5 mb-md-6">What is an E-2 visa?</h2>
              <p className="text-body mb-6">
                The E-2 investor visa is the perfect business visa solution for foreign
                entrepreneurs and investors who want to start their own business in the
                United States. The E-2 investor visa is a nonimmigrant visa that allows
                foreign investors to live in the United States while directing and
                developing their business. The E-2 visa USA program is a great alternative
                to the EB-5 US investor green card because the program lacks minimum
                investment and residence requirements.
              </p>
              <div className="accordion" id="corporateLaw">
                <div className="mb-5">
                  <div id="e2VisaAccordionOne">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaCollapseOne"
                      aria-expanded="true"
                      aria-controls="e2VisaCollapseOne">
                      <strong>Flexibility of E-2 visa USA program</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaCollapseOne"
                    className="collapse show"
                    aria-labelledby="e2VisaAccordionOne"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The E-2 visa USA program permits the beneficiary to extend her
                      investor visa indefinitely, as long as the E-2 investor continues to
                      comply with the regulations. This offers a lot of flexibility,
                      allowing the investor to stay in the United States beyond the
                      initial (generally 5-year) term of the E-2 visa. Moreover, the E-2
                      business visa program does not require the investor to permanently
                      reside in the United States, as long as the E-2 investor continues
                      to direct and develop the US business. Furthermore, the investor may
                      return to her country of origin and abandon her residence in the
                      United States at any time without any official abandonment
                      procedures. This flexibility makes the E-2 visa USA program one of
                      the best investor visa solutions.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaAccordionTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaCollapseTwo"
                      aria-expanded="false"
                      aria-controls="e2VisaCollapseTwo">
                      <strong>
                        No required minimum investment amount for E-2 investor visa
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaCollapseTwo"
                    className="collapse"
                    aria-labelledby="e2VisaAccordionTwo"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      Unlike the EB-5 investor green card, the E-2 visa USA program does
                      not have a minimum investment amount. This makes the E-2 investor
                      visa a great option if your US business does not have high startup
                      expenses or you wish to invest less than the EB-5 minimum investment
                      amount. For more information on the minimum investment amount, other
                      E-2 visa requirements, or how to get an E-2 visa, please see the
                      requirements section below.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaAccordionThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaCollapseThree"
                      aria-expanded="false"
                      aria-controls="e2VisaCollapseThree">
                      <strong>Family E-2 visa</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaCollapseThree"
                    className="collapse"
                    aria-labelledby="e2VisaAccordionThree"
                    data-parent="#corporateLaw">
                    <p className="text-body mt-4 mb-5">
                      The E-2 visa USA program allows your family members to accompany you
                      to the United States. Your spouse and children under the age of 21
                      may apply for an E-2 dependent visa together with you. Your children
                      under 21 can attend school on their E-2 visa, while your spouse is
                      authorized to work for US employers with an E-2 EAD (Employment
                      Authorization Document).
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-5">
                <div id="e2VisaAccordionFour">
                  <h2
                    className="
									text-title text-primary
									accordion-trigger
									collapsed
								"
                    data-bs-toggle="collapse"
                    data-bs-target="#e2VisaCollapseFour"
                    aria-expanded="false"
                    aria-controls="e2VisaCollapseFour">
                    <strong>E-2 visa for foreign employees moving to US operation</strong>
                  </h2>
                </div>
                <div
                  id="e2VisaCollapseFour"
                  className="collapse"
                  aria-labelledby="e2VisaAccordionFour"
                  data-parent="#corporateLaw">
                  <p className="text-body mt-4 mb-5">
                    If you have foreign employees with essential skills or in managerial
                    positions whom you would like to relocate to the US, the E-2 visa USA
                    program could be a great option for you. Once your US company is
                    registered as an E-2 business with the local consulate in your country
                    of origin, you can use the E-2 visa USA program to relocate your
                    foreign employees to your US operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-requirements.jpg"
                alt="What are the E-2 visa requirements?"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                What are the E-2 visa requirements?
              </h2>
              <p className="text-body mb-6">
                To qualify for the E-2 visa USA program you must meet all the
                requirements, which are summarized below:
              </p>
              <div className="accordion" id="e2VisaRequirements">
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseZero"
                      aria-expanded="true"
                      aria-controls="e2VisaRequirementsCollapseZero">
                      <strong>E-2 treaty country nationality</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseZero"
                    className="collapse show"
                    aria-labelledby="e2VisaRequirementsHeadingZero"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        The E-2 visa USA applicant must be a citizen of a country which
                        has a treaty of friendship, commerce, and navigation with the
                        United States. You may only apply for an E-2 investor visa if you
                        are citizen of such an E-2 treaty country. Please scroll down to
                        see a complete list of all E-2 visa countries.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseOne"
                      aria-expanded="false"
                      aria-controls="e2VisaRequirementsCollapseOne">
                      <strong>
                        E-2 Substantial investment amount (E-2 visa how much to invest?)
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseOne"
                    className="collapse"
                    aria-labelledby="e2VisaRequirementsHeadingOne"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        There is no preset minimum investment amount to qualify for the
                        E-2 visa USA program. Even though there is no required minimum
                        investment amount, the E-2 visa regulations require that the
                        applicant make a substantial investment.
                        <br />
                        <br />
                        What size of investment is sufficient to meet the E-2 substantial
                        investment amount requirement? To satisfy the requirements, the
                        invested amount must ensure the successful operation of the US
                        enterprise. Generally, US immigration authorities view investments
                        above $100,000 as substantial. However, this is a general
                        guideline and should not be interpreted as an absolute minimum E-2
                        investment amount. As an experienced E-2 visa lawyer, I have
                        successfully represented E-2 investor visa applicants who were
                        approved with investments below $50,000. In such cases, the
                        business has low startup costs, as is often found in professional
                        service industries.
                        <br />
                        <br />
                        The E-2 visa requirements also mandate that the invested amount be
                        proportional to the regular startup expenses for the type of
                        business you are commencing in the United States. This means that
                        the type of business in to which you are investing will affect the
                        minimum investment you are required to make to qualify for the E-2
                        visa USA program. For example, a factory or other manufacturing
                        facility will require a significantly higher minimum investment
                        amount than a consulting business with low startup expenses.
                        <br />
                        <br />
                        <i>
                          <strong>
                            &gt; What is the minimum E-2 visa investment amount for
                            purchasing an existing business?
                          </strong>
                        </i>
                        <br />
                        The E-2 visa USA rules allow for various E-2 investment
                        opportunities. As an E-2 visa lawyer, I am often asked whether the
                        investor can purchase an existing US business? The answer is yes.
                        You can qualify for the E-2 visa USA program by purchasing an
                        already existing business. In such cases, the E-2 visa minimum
                        investment is the cost of purchasing the business. Please note,
                        funds obtained through secured loans or gifts are generally
                        acceptable for E-2 investor visa purposes as they are considered
                        personal funds belonging to the investor. As part of our E-2 visa
                        lawyer services, we will advise you whether your proposed E-2
                        immigration visa investment amount is substantial. We will also
                        develop a custom-tailored immigration strategy for you and your
                        family.
                        <br />
                        <br />
                        <i>
                          <strong>
                            &gt; What is the minimum E-2 visa investment amount for
                            starting a new business?
                          </strong>
                        </i>
                        <br />
                        Starting a new business is the most common way for an investor to
                        qualify for the E-2 visa USA program. When starting a new E-2
                        business, the minimum E-2 investment amount is usually the cost of
                        establishing the business to the point where it is operational.
                        You should keep in mind that the minimum investment costs can
                        significantly vary due to differences between various businesses.
                        Please note, according to the E-2 visa requirements, simply
                        depositing funds into a business bank account with an intent to
                        invest in the future is insufficient for showing the E-2 visa USA
                        applicant’s commitment to the business.
                        <br />
                        <br />
                        Our E-2 visa lawyer services include determining whether your
                        business idea qualifies for an E-2 investor visa and guiding you
                        through all phases of the E-2 immigration visa process, including:
                        investment planning, transaction structuring, and the E-2 visa
                        application.
                        <br />
                        <br />
                        <i>
                          <strong>
                            &gt; E-2 visa USA pitfalls: avoiding passive E-2 investments
                          </strong>
                        </i>
                        <br />A passive investment is any investment that provides
                        financial gains without the E-2 visa USA investor’s active
                        day-to-day involvement in the business. For example, many
                        investors wish to purchase real estate to be rented out for
                        profit. Unfortunately, such an investment will likely not qualify
                        for an E-2 investor visa even if you purchase the properties
                        through a company and hire an employee to perform repairs and
                        maintenance. The E-2 investor visa regulations require the
                        investor’s active involvement in managing and developing the
                        business. To follow the above example, a rental property
                        management business, in which the E-2 investor purchases real
                        properties and has a management team in place which actively
                        leases and maintains rental units, will have a much higher
                        likelihood of approval because such a business requires active
                        day-to-day management of the business. Our experienced E-2 visa
                        lawyer will evaluate whether your proposed E-2 visa USA business
                        qualifies as an active investment and will help you ensure that
                        your proposed E-2 business visa enterprise meets all E-2 visa
                        requirements and rules.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseTwo"
                      aria-expanded="false"
                      aria-controls="e2VisaRequirementsCollapseTwo">
                      <strong>Non-marginal investment in E-2 business</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseTwo"
                    className="collapse"
                    aria-labelledby="e2VisaRequirementsHeadingTwo"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        The E-2 visa business cannot be marginal. This means that the E-2
                        business cannot solely provide a living for the applicant and her
                        family. Based on the E-2 visa requirements the business must grow
                        and generate sufficient income to require the hiring of US workers
                        in the near future. Because of this requirement many E-2 visa USA
                        investors start new ventures or buy distressed businesses which
                        they can overhaul and develop at a significant profit, thereby
                        also satisfying the non-marginality requirement.
                        <br />
                        <br />
                        If you are planning on buying an existing business as your
                        qualifying E-2 investment, it is very important to consider the
                        company’s current financials. Immigration authorities may consider
                        your E-2 investment marginal if the current business does not earn
                        sufficient income. For example, you may have found a business that
                        employs three employees and has great growth potential, but the
                        financial statements and tax returns show an annual income of only
                        $25,000. The Consular Officer reviewing your E-2 visa application
                        will likely consider your investment in this business to be
                        marginal because of the low profits.
                        <br />
                        <br />
                        As part of our E-2 visa lawyer services we determine whether your
                        proposed E-2 visa investment satisfies the non-marginality
                        requirement and whether your business meets all other E-2 visa
                        requirements and rules.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseThree"
                      aria-expanded="false"
                      aria-controls="e2VisaRequirementsCollapseThree">
                      <strong>Active investment E-2 visa requirement</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseThree"
                    className="collapse"
                    aria-labelledby="e2VisaRequirementsHeadingThree"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        The E-2 visa USA program requires the investor to be actively in
                        the process of investing or to have already made an investment in
                        the business. To meet the E-2 visa requirements, the investor’s
                        funds must be at a risk of loss and irrevocably committed. Please
                        note, the E-2 investor visa enterprise does not have to be
                        operating during the visa application process. However, to be
                        approved for the E-2 visa USA program, your business needs to be
                        ready to start operating once the E-2 visa is approved.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseFour"
                      aria-expanded="false"
                      aria-controls="e2VisaRequirementsCollapseFour">
                      <strong>Control and Source of Funds of E-2 investment</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseFour"
                    className="collapse"
                    aria-labelledby="e2VisaRequirementsHeadingFour"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        To qualify for the E-2 visa USA program, the invested funds must
                        indisputably belong to the visa applicant. However, the E-2 visa
                        USA rules permit the investor to use gifts from friends,
                        relatives, or third parties as part of the E-2 investor visa
                        investment. Under the rules, secured personal loans can also be
                        counted as part of the investment.
                        <br />
                        <br />
                        The E-2 visa requirements also mandate that the investor show the
                        lawful source of the invested funds. As part of our E-2 visa
                        lawyer services we will represent you throughout the entire E-2
                        visa process, from the initial investment transactions through
                        your E-2 business visa USA application, including documenting your
                        control of and the lawful source of your investment funds.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingFive">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseFive"
                      aria-expanded="false"
                      aria-controls="e2VisaRequirementsCollapseFive">
                      <strong>
                        Willingness to develop and direct the E-2 enterprise
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseFive"
                    className="collapse"
                    aria-labelledby="e2VisaRequirementsHeadingFive"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        The E-2 visa USA program requires that the investor develop and
                        direct the E-2 business. Although the investor can hire managers
                        to oversee the day-to-day functions of the business, the E-2
                        investor cannot have an entirely passive role in the E-2
                        enterprise. Because of requirement, it is helpful to demonstrate
                        how your skills and experience will allow you to successfully
                        develop and direct the E-2 business. Please note, the E-2 visa USA
                        program requires that the investor own at least 50% of the E-2
                        business.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaRequirementsHeadingSix">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaRequirementsCollapseSix"
                      aria-expanded="false"
                      aria-controls="e2VisaRequirementsCollapseSix">
                      <strong>Must intend to depart the United States</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaRequirementsCollapseSix"
                    className="collapse"
                    aria-labelledby="e2VisaRequirementsHeadingSix"
                    data-parent="#e2VisaRequirements">
                    <div className="my-4">
                      <p className="text-body">
                        To qualify for an E-2 investor visa, the applicant must express an
                        intent to depart the United States after expiration of the
                        investor’s visa. Although the E-2 investor visa applicant cannot
                        intend to remain in the United States permanently, the E-2 visa
                        USA rules allow the applicant to extend her visa indefinitely as
                        long as she continues to meet all E-2 visa requirements.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-countries.jpg"
                alt="E-2 visa countries and E-2 visa validity period"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                E-2 visa countries and E-2 visa validity period
              </h2>
              <p className="text-body mb-5">
                To be eligible for the E-2 visa USA program, you must be a citizen of a
                country with a treaty of Commerce and Navigation with the United States.
                Unfortunately, not all countries have such an E-2 treaty with the United
                States. Some important E-2 country exceptions are China, India, and
                Vietnam. In Europe, citizens of Hungary and Russia do not qualify for an
                E-2 investor visa. Citizens of countries lacking a treaty with the United
                States cannot qualify for the E-2 visa USA program. So if you are
                wondering “Is India eligible for E-2 visa?” or “Can I apply for E-2 visa
                USA from India?” then the answer is, unfortunately, “No, India is not
                eligible for E-2 visa.”
              </p>
              <div className="accordion" id="E2VisaCountries">
                <div className="mb-5">
                  <div id="E2VisaCountriesHeadingZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#E2VisaCountriesCollapseZero"
                      aria-expanded="true"
                      aria-controls="E2VisaCountriesCollapseZero">
                      <strong>E-2 visa validity period</strong>
                    </h2>
                  </div>
                  <div
                    id="E2VisaCountriesCollapseZero"
                    className="collapse show"
                    aria-labelledby="E2VisaCountriesHeadingZero"
                    data-parent="#E2VisaCountries">
                    <p className="text-body mt-4 mb-5">
                      The length of the E-2 investor visa validity varies from country to
                      country. There are two factors that influence the visa validity
                      period: the
                      <strong>reciprocity period</strong> and the
                      <strong>consular officer</strong>.<br />
                      <br />
                      <i>
                        <strong>&gt; What is the E-2 reciprocity period?</strong>
                      </i>
                      <br />
                      The E-2 reciprocity period is the maximum period of time for which
                      an E-2 visa USA can be issued. This maximum length of the E-2 visa
                      depends on the Treaty of Trade and Commerce between the United
                      States and the applicant’s country of citizenship. This period may
                      vary between 3 months and 5 years. The validity period represents
                      the amount of time the E-2 immigration visa holder has to enter the
                      United States. If your E-2 business visa is valid for 3 months, you
                      must enter the United States during that 3-month E-2 visa validity
                      period. Please note, the amount of entries allowed on an E-2 visa
                      into the United States also depend on the E-2 treaty between the
                      investor’s country of citizenship and the United States. For
                      example, citizens of Bolivia may only use their E-2 investor visa to
                      enter the United States one (1) time. While citizens of Germany, may
                      use their E-2 investor visa to enter the United States as many times
                      as they wish during the visa validity period.
                      <br />
                      <br />
                      <i>
                        <strong>&gt; What is the E-2 admission period?</strong>
                      </i>
                      <br />
                      The E-2 admission period is the amount of time an E-2 investor visa
                      holder may remain in the United States after entry. It is critical
                      not to confuse this period with the E-2 visa validity period
                      described above. Once you enter the United States on your E-2
                      investor visa, you will be allowed to remain in the United States
                      for 2 years to direct and develop your US enterprise.
                      <br />
                      <br />
                      At the end of this 2-year E-2 admission period, you can renew your
                      E-2 visa status with USCIS for an additional 2-year term while
                      remaining in the United States. Instead of renewing with USCIS, if
                      your E-2 visa is still valid at the end of this 2-year period, you
                      may leave the United States and reenter using your valid E-2 visa.
                      Upon reentry you should be admitted for another 2-year term.
                      However, if your E-2 visa has expired when you leave the United
                      States, you will need to renew your E-2 investor visa at a US
                      consulate or embassy abroad and use the newly obtained E-2 visa to
                      enter the United States.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; How can a consular officer impact your E-2 visa validity
                          period?
                        </strong>
                      </i>
                      <br />
                      Your E-2 visa validity period is also affected by the consular
                      officer reviewing your E-2 visa application. The consular officer
                      may issue your E-2 investor visa for the maximum validity period
                      allowed by the reciprocity rules explained above. However, the
                      officer may also decide to issue your E-2 investor visa for a
                      shorter period. There may be various reasons for this, such as the
                      consular officer doubting if you will achieve your goals presented
                      in your E-2 visa USA application.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="E2VisaCountriesHeadingOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#E2VisaCountriesCollapseOne"
                      aria-expanded="false"
                      aria-controls="E2VisaCountriesCollapseOne">
                      <strong>
                        List of E-2 visa countries eligible for the E-2 visa USA program
                      </strong>
                    </h2>
                  </div>
                  <div
                    id="E2VisaCountriesCollapseOne"
                    className="collapse"
                    aria-labelledby="E2VisaCountriesHeadingOne"
                    data-parent="#E2VisaCountries">
                    <p className="text-body mt-4 mb-5">
                      The table below lists all E-2 countries citizens of which currently
                      qualify under the E-2 investor visa USA program. The table also
                      lists all maximum validity periods (E-2 reciprocity periods) and the
                      number of entries allowed on the E-2 investor visa.
                    </p>
                    <div className="table-responsive">
                      <table className="table table-bordered general-table">
                        <thead>
                          <tr>
                            <th scope="col" className="text-primary e-2-table">
                              <strong>E-2 Treaty Country</strong>
                            </th>
                            <th scope="col" className="text-primary e-2-table">
                              <strong>How Long E-2 Visa can be issued for</strong>
                            </th>
                            <th scope="col" className="text-primary e-2-table">
                              <strong>Number of Entries</strong> to the U.S. Permitted
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Albania</td>
                            <td>3 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Argentina</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Armenia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Australia</td>
                            <td>4 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Austria</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Azerbaijan</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>Bahrain</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>Bangladesh</td>
                            <td>3 Months</td>
                            <td>Two</td>
                          </tr>
                          <tr>
                            <td>Belgium</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Bolivia</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>*Yugoslavia: Bosnia and Herzegovina</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Bulgaria</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Cameroon</td>
                            <td>1 Year</td>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>Canada</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Chile</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>China (Taiwan)</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Colombia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Congo (Brazzaville)</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>Congo (Kinshasa)</td>
                            <td>3 Months</td>
                            <td>Two</td>
                          </tr>
                          <tr>
                            <td>Costa Rica</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>*Yugoslavia: Croatia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Czech Republic</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Denmark</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Ecuador</td>
                            <td>3 Months</td>
                            <td>Two</td>
                          </tr>
                          <tr>
                            <td>Egypt</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>Estonia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Ethiopia</td>
                            <td>6 Months</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Finland</td>
                            <td>2 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>France</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Georgia</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Germany</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Grenada</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Honduras</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Iran</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>Ireland</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Israel</td>
                            <td>2 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Italy</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Jamaica</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Japan</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Jordan</td>
                            <td>3 Months</td>
                            <td>One</td>
                          </tr>
                          <tr>
                            <td>Kazakhstan</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Korea (South)</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>*Yugoslavia: Kosovo</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Kyrgyzstan</td>
                            <td>3 Months</td>
                            <td>Two</td>
                          </tr>
                          <tr>
                            <td>Latvia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Liberia</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Lithuania</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Luxembourg</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>*Macedonia, The Former Yugoslav Republic of (FRY)</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Mexico</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Moldova</td>
                            <td>3 Months</td>
                            <td>Two</td>
                          </tr>
                          <tr>
                            <td>Mongolia</td>
                            <td>3 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>*Yugoslavia: Montenegro</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Morocco</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Netherlands</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Norway</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Oman</td>
                            <td>6 Months</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Pakistan</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Panama</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Paraguay</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Philippines</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Poland</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Romania</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>*Yugoslavia: Serbia</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Senegal</td>
                            <td>1 Year</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Singapore</td>
                            <td>2 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Slovak Republic (Slovakia)</td>
                            <td>2 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>*Yugoslavia: Slovenia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Spain</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Sri Lanka</td>
                            <td>3 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Suriname</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Sweden</td>
                            <td>2 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Switzerland</td>
                            <td>4 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Thailand</td>
                            <td>6 Months</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Togo</td>
                            <td>3 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Trinidad and Tobago</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Tunisia</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Turkey</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                          <tr>
                            <td>Ukraine</td>
                            <td>3 Months</td>
                            <td>Two</td>
                          </tr>
                          <tr>
                            <td>United Kingdom</td>
                            <td>5 Years</td>
                            <td>Multiple</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-process.jpg"
                alt="E-2 visa process"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">E-2 visa process</h2>
              <p className="text-body mb-6">
                There are two ways to apply for the E-2 visa USA program: (1) in the
                United States by filing a petition with USCIS or (2) outside the United
                States by applying at a US consulate or embassy.
              </p>
              <div className="accordion" id="e2VisaProcessAccordion">
                <div className="mb-5">
                  <div id="e2VisaProcessItemZero">
                    <h2
                      className="text-title text-primary accordion-trigger"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaProcessItemTargetZero"
                      aria-expanded="true"
                      aria-controls="e2VisaProcessItemTargetZero">
                      <strong>E-2 visa USA application process with USCIS</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaProcessItemTargetZero"
                    className="collapse show"
                    aria-labelledby="e2VisaProcessItemZero"
                    data-parent="#e2VisaProcessAccordion">
                    <p className="text-body mb-4">
                      If you are currently in the United States and wish to obtain an E-2
                      investor visa, you must file a change of status petition. You may do
                      so by submitting Form I-129 and supporting documentation with United
                      States Citizenship & Immigration Services (USCIS). There is no
                      in-person interview process when filing an E-2 visa USA petition
                      with USCIS. If USCIS has additional questions or requires additional
                      documents, the agency will issue a Request for Evidence detailing
                      the required information or documents. It is important to note that
                      you will not be able to speak to the officer reviewing your
                      application at any point in the process. Once your E-2 visa USA
                      change of status petition is approved, you will receive a Form I-797
                      Approval Notice from USCIS.
                      <br />
                      <br />
                      An approval of an E-2 change of status petition grants the applicant
                      2 years of status during which the applicant may reside and develop
                      her E-2 business in the United States. However, an E-2 change of
                      status approval does not grant the applicant an E-2 visa. If you
                      leave the country after your change of status petition is approved,
                      you must obtain an E-2 investor visa at a US consulate or embassy to
                      be able to reenter the United States.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; What is the E2 visa processing time with USCIS?
                        </strong>
                      </i>
                      <br />
                      The E-2 visa processing time with USCIS usually takes up to 6 months
                      depending on USCIS’s case load. USCIS offers a premium processing
                      service for an additional fee of $1,440 which guarantees a response
                      in 15 days.
                      <br />
                      <br />
                      As part of our E-2 visa lawyer services we will assist you with your
                      change of status application with USCIS and guide you through every
                      step of your E-2 visa USA process.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaProcessItemOne">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaProcessItemTargetOne"
                      aria-expanded="false"
                      aria-controls="e2VisaProcessItemTargetOne">
                      <strong>E-2 visa USA application process at a Consulate</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaProcessItemTargetOne"
                    className="collapse"
                    aria-labelledby="e2VisaProcessItemOne"
                    data-parent="#e2VisaProcessAccordion">
                    <p className="text-body mb-4">
                      If you apply for an E-2 investor visa outside the United States you
                      have to file your application at a US consulate or embassy. To
                      qualify for the visa, you must submit Forms DS-160 and DS-156E along
                      with supporting documentation showing that you have met the E-2 visa
                      USA requirements. Before being granted an E-2 investor visa, you
                      will be required to attend an in-person interview during which the
                      consular officer will ask questions about your investment.
                      <br />
                      <br />
                      <i>
                        <strong>
                          &gt; What is the E2 visa processing time when applying at a
                          consulate?
                        </strong>
                      </i>
                      <br />
                      The E-2 visa processing time when applying at a consulate outside
                      the United States can significantly vary depending on the particular
                      consulate at which the E-2 visa USA application is filed. At
                      smaller, less busy consular posts, the application process can take
                      as little as 6 weeks, while at larger, busier consulates it can take
                      up to 3 months.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaProcessItemTwo">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaProcessItemTargetTwo"
                      aria-expanded="false"
                      aria-controls="e2VisaProcessItemTargetTwo">
                      <strong>E-2 visa interview experience</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaProcessItemTargetTwo"
                    className="collapse"
                    aria-labelledby="e2VisaProcessItemTwo"
                    data-parent="#e2VisaProcessAccordion">
                    <p className="text-body mb-4">
                      During your E-2 visa interview the consular official will generally
                      take approximately half an hour to review your application. However,
                      if the officer requires additional information to determine whether
                      you meet the E-2 visa requirements, the interview could take much
                      longer. It is best to come prepared for your E-2 visa interview.
                      Please see below some useful tips for your interview:
                    </p>
                    <ul className="list-bullets">
                      <li>
                        Make sure you know your E-2 immigration visa business plan in
                        detail.
                      </li>
                      <li>
                        Be prepared to thoroughly explain why your E-2 immigration visa
                        business plan will be successful and how you meet the E-2 visa
                        requirements.
                      </li>
                      <li>
                        Make sure you know the information and answers you provided on
                        your DS-160 application. Some of the questions during your
                        interview may be the same.
                      </li>
                      <li>
                        You should always answer interview questions honestly and
                        succinctly.
                      </li>
                    </ul>
                    <p className="text-body mb-4">
                      As part of our E-2 visa lawyer services we will assist you with your
                      E-2 visa USA application with a US consulate. We will guide you
                      through every step of the application process and help you prepare
                      for your E-2 visa interview.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaProcessItemThree">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaProcessItemTargetThree"
                      aria-expanded="false"
                      aria-controls="e2VisaProcessItemTargetThree">
                      <strong>What does having an E-2 visa mean?</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaProcessItemTargetThree"
                    className="collapse"
                    aria-labelledby="e2VisaProcessItemThree"
                    data-parent="#e2VisaProcessAccordion">
                    <p className="text-body mb-4">
                      A visa allows you to enter the United States. To obtain an E-2 visa,
                      you must apply for an E-2 investor visa at a US consulate or embassy
                      abroad. If approved, an E-2 visa will be placed in your passport.
                      When you arrive in the United States, you will present your visa in
                      your passport to the US customs officer. The officer can permit you
                      to enter the United States based on the visa in your passport. It is
                      best to think of the visa in your passport as an entry document to
                      be used when entering the United States. If you have a multiple
                      entry E-2 visa which is valid for 5 years, you can leave and reenter
                      the United States using your E-2 visa an unlimited amount of time
                      within the 5-year validity window.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaProcessItemFour">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaProcessItemTargetFour"
                      aria-expanded="false"
                      aria-controls="e2VisaProcessItemTargetFour">
                      <strong>What does being in E-2 status mean?</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaProcessItemTargetFour"
                    className="collapse"
                    aria-labelledby="e2VisaProcessItemFour"
                    data-parent="#e2VisaProcessAccordion">
                    <p className="text-body mb-4">
                      Status determines the length of time you are allowed to remain in
                      the US after admission. E-2 investor visa holders are admitted in
                      E-2 status which provides a 2-year admission period. The E-2 visa
                      USA holder may remain in the United States to direct and develop her
                      US business for 2 years. Before the end of this period, the E-2
                      business visa holder must leave and reenter the United States or
                      apply for an extension of E-2 status with USCIS. For example, a
                      Turkish citizen who holds a 5-year multiple entry E-2 visa, must
                      depart the United States before the end of the 2-year E-2 admission
                      period. Then, when she returns using her 5-year E-2 investor visa,
                      she will be admitted in E-2 status for 2 years again. Every time an
                      E-2 visa holder enters the United States, the maximum admission
                      period in E-2 status is 2 years.
                    </p>
                  </div>
                </div>
                <div className="mb-5">
                  <div id="e2VisaProcessItemFive">
                    <h2
                      className="
										text-title text-primary
										accordion-trigger
										collapsed
									"
                      data-bs-toggle="collapse"
                      data-bs-target="#e2VisaProcessItemTargetFive"
                      aria-expanded="false"
                      aria-controls="e2VisaProcessItemTargetFive">
                      <strong>Validity period of E-2 status vs E-2 visa</strong>
                    </h2>
                  </div>
                  <div
                    id="e2VisaProcessItemTargetFive"
                    className="collapse"
                    aria-labelledby="e2VisaProcessItemFive"
                    data-parent="#e2VisaProcessAccordion">
                    <p className="text-body mb-4">
                      If you apply for an E-2 visa through a change of status, the
                      validity of your new E-2 status is 2 years. The E-2 visa duration
                      granted at a US Consulate may be up to 5 years depending on the
                      country specific reciprocity period. Please see the table above to
                      see the maximum validity period for your country of citizenship.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-renewal.jpg"
                alt="E-2 visa renewal"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">E-2 visa renewal</h2>
              <div className="mt-3">
                <strong>How to renew E-2 visa in USA?</strong>
                <p className="text-body mt-3">
                  If you choose to proceed with your E-2 visa renewal in the United
                  States, you need to file a petition to extend your E-2 status with
                  USCIS. If approved, you will be granted a maximum of 2 additional years
                  in E-2 status. If you do not plan to leave the United States, you can
                  apply for an E-2 visa renewal every 2 years indefinitely, as long as you
                  continue to meet all E-2 visa requirements. You should keep in mind that
                  the extension of E-2 status with USCIS will not be valid if you leave
                  the United States. If you depart the United States after obtaining an
                  E-2 status extension from USCIS, you will have to file an E-2 visa USA
                  application at a US consulate to obtain a visa renewal.
                  <br />
                  <br />
                  As part of our E-2 visa lawyer services we will assist you with your E-2
                  visa renewal application with USCIS and guide you through every step of
                  the process.
                </p>
              </div>
              <div className="mt-5">
                <strong>E-2 visa renewal application at a Consulate</strong>
                <p className="text-body mt-3">
                  To obtain an E-2 visa renewal at a US Consulate abroad, you must file an
                  E-2 visa USA application with the consulate. Unlike an extension of
                  status filed with USCIS, if your E-2 visa renewal application is
                  approved by the consulate, you will be able to travel in and out of the
                  United States during your E-2 visa validity period. You are probably
                  wondering if you should file your E-2 visa renewal application at the
                  same consulate where you filed your initial E-2 visa application. Since
                  the designated consulate will already have a record of your investor
                  visa business with all the relevant information and documentation, it is
                  generally advisable to submit your E-2 visa renewal application at the
                  same consulate where your initial E-2 visa application was granted.
                  <br />
                  <br />
                  As part of our E-2 visa lawyer services we will assist you with your
                  visa renewal application at a consulate and guide you through every step
                  of the E-2 visa renewal process.
                </p>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-green-card.jpg"
                alt="Does E-2 visa lead to green card?"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">
                Does E-2 visa lead to green card?
              </h2>
              <p className="text-body">
                An E-2 visa by itself does not lead to a green card. However, an E-2
                investor visa can lead to a green card, if certain additional steps are
                taken by the E-2 investor. Below are two ways the E-2 visa USA process can
                lead to a green card:
              </p>
              <div className="mt-5">
                <strong>Invest more and obtain an EB-5 green card</strong>
                <p className="text-body mt-3">
                  If you continue to invest significant funds into your E-2 business, your
                  total investment may meet the minimum investment requirements for an
                  EB-5 green card. If you have invested a minimum of $900,000 or
                  $1,800,000 depending of the geographic area of your business and hired a
                  minimum of ten US workers, you may qualify for an EB-5 green card. While
                  pursuing your EB-5 investor green card, you may continue to operate your
                  E-2 visa USA business and maintain your E-2 investor status. As an
                  experienced E-2 visa lawyer, I would be happy to evaluate your case to
                  answer the question “Can an E-2 visa lead to green card?” in your case.
                </p>
              </div>
              <div className="mt-5">
                <strong>Invest in a Regional Center and obtain an EB-5 green card</strong>
                <p className="text-body mt-3">
                  Instead of directly investing the minimum investment amount into your
                  own E-2 visa USA business, you can choose to invest in an EB-5 regional
                  center project. This E-2 visa to green card solution offers a more
                  hands-off management approach. However, it comes at a higher cost as the
                  funds invested into the E-2 business cannot be used as part of the EB-5
                  investment. As an experienced E-2 visa lawyer, I would be happy to
                  answer the question, “Can an E-2 visa lead to green card?” in your case
                  and help you decide on the best green card option for your family.
                </p>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-advantages.jpg"
                alt="E-2 visa advantages"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">E-2 visa advantages</h2>
              <div className="mt-5">
                <strong>E-2 visa dependent work permit</strong>
                <p className="text-body mt-3">
                  One of the main advantages of the E-2 visa USA program is that the
                  spouse of the investor visa holder is eligible to apply for an E-2 visa
                  dependent work permit. This E-2 visa dependent work permit is known as
                  an E-2 EAD (Employment Authorization Card). Once the E-2 EAD is
                  approved, your spouse will also be able to apply for an E-2 visa social
                  security number. With the EAD, your spouse will be able to work for
                  almost all US employers. For instance, E-2 visa USA dependent spouses
                  may work as an employee in the E-2 business visa enterprise, or could
                  start their own business, or work for another US company or
                  organization. Please note, your spouse cannot apply for an E-2 visa
                  dependent work permit before you enter the United States in E-2 status.
                </p>
              </div>
              <div className="mt-5">
                <strong>How to apply for an E-2 visa dependent work permit?</strong>
                <p className="text-body mt-3">
                  To apply for an E-2 visa dependent work permit you need to file a Form
                  I-765 along with all required evidence with USCIS. As part of our E-2
                  visa lawyer services we offer assistance in preparing and submitting all
                  the necessary documentation for an E-2 visa dependent work permit.
                </p>
              </div>
              <div className="mt-5">
                <strong>How to apply for E-2 visa social security number?</strong>
                <p className="text-body mt-3">
                  Once your E-2 visa and your spouse’s E-2 dependent work permit are
                  approved, you will be eligible to apply for an E-2 visa social security
                  number. To apply for E-2 visa social security number you need to file a
                  Form SS-5 with the Social Security Administration (SSA).
                </p>
              </div>
              <div className="mt-5">
                <strong>E-2 visa tax exemption</strong>
                <p className="text-body mt-3">
                  In some cases, the E-2 visa USA program may offer tax advantages for the
                  investor compared to an EB-5 investor green card. The E-2 visa may be a
                  great choice for investors who will be physically present in the United
                  States for less than 122 days per year. In such cases, the investor may
                  avoid being classified as a tax resident of the United States. This
                  would mean that the E-2 investor does not have to pay tax on her global
                  income, just the income earned in the United States. International
                  taxation is a complex subject. We highly advise you to seek the advice
                  of an experienced tax professional before choosing the E-2 investor visa
                  program for any tax advantages it may offer.
                </p>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="offset-lg-1 col-lg-5 order-lg-2 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-2-visa-employee.jpg"
                alt="E-2 employee visas"
              />
            </div>
            <div className="col-lg-6 order-lg-1">
              <h2 className="text-headline mb-5 mb-md-6">E-2 employee visas</h2>
              <p className="text-body">
                The E-2 visa USA program is a great solution for relocating your foreign
                employees to the United States. Such E-2 employee visas enjoy the same
                benefits as E-2 investor visas, including eligibility for spouses and
                children to accompany the E-2 employee to the United States. The spouse of
                an E-2 employee visa holder can apply for an E-2 visa dependent work
                permit and may work in the United States, while the children of an E-2
                employee visa holder may attend school in the United States. To qualify
                for an E-2 employee visa, the employee must have the same nationality as
                the owner of the E-2 business.
              </p>
              <div className="mt-5">
                <strong>E-2 specialist visa (essential skill employees)</strong>
                <p className="text-body mt-3">
                  An E-2 enterprise can file an E-2 employee visa application for an
                  essential skill or specialist employee. Such an E-2 specialist visa
                  applicant must demonstrate that she has specialized and unique skills or
                  knowledge that are essential to the success of the E-2 visa USA
                  business. Some of the points immigration officers consider, are the
                  salary offered for the position, how much training and experience is
                  required to gain the expertise, whether there are US workers with the
                  same expertise, and how long the US business needs the E-2 specialist
                  visa employee’s services. Generally, it is easier to obtain an E-2
                  specialist visa for shorter durations by showing that the applicant has
                  essential skills needed for the company’s start-up process or for the
                  training of US employees who will eventually perform these duties at the
                  US enterprise. To obtain an E-2 specialist visa for a longer duration,
                  the applicant will need to prove that the US enterprise needs these
                  special skills continuously and that no US worker can be trained to
                  perform the specialist’s position. As an experienced E-2 visa lawyer, I
                  can help your organization apply for an E-2 specialist visa for your
                  essential skill employees.
                </p>
              </div>
              <div className="mt-5">
                <strong>E-2 manager visa</strong>
                <p className="text-body mt-3">
                  The E-2 enterprise may also apply for an E-2 manager visa on behalf of
                  its foreign employees. To qualify for an E-2 manager visa, the applicant
                  must demonstrate that she will have a high-level position within the US
                  business. For example, if the manager visa applicant has control over
                  key functions of the business, then she is likely a good candidate for
                  an E-2 manager visa. To demonstrate the managerial nature of the visa
                  applicant’s position, the E-2 enterprise may provide company
                  organizational charts, job descriptions, and salary information. As part
                  of our E-2 visa lawyer services, we will evaluate whether your employees
                  qualify for an E-2 manager visa and help you devise the best strategy
                  for relocating your managers to your US operation.
                </p>
              </div>
            </div>
          </div>
          <hr className="separator-row mt-8 mb-8 mt-md-13 mb-md-13" />
          <div className="row">
            <div className="col-lg-5 mb-6 mb-lg-0">
              <StaticImage
                className="img-fluid lazy"
                src="../assets/img/e-1-vs-e-2-visa-differences.jpg"
                alt="What is the difference between E1 and E-2 visa?"
              />
            </div>
            <div className="offset-lg-1 col-lg-6">
              <h2 className="text-headline mb-5 mb-md-6">
                What is the difference between E1 and E-2 visa?
              </h2>
              <p className="text-body mt-5">
                Both E1 and E-2 visas are reserved for citizens of treaty countries,
                however there are significant differences between the two treaty country
                visas. First, although both E1 and E-2 visa USA programs require that the
                applicant be a citizen of a treaty country, the list of eligible treaty
                countries differs between the E1 and E-2 visa USA programs. The E1 visa
                program has fewer eligible countries compared to the E-2 visa USA program.
                <br />
                <br />
                The main difference between the E1 and E-2 visa USA programs is that the
                E1 visa USA is reserved for traders from treaty countries to engage in
                substantial trade between the US and their home country. The term “trade”
                may refer to goods or services. Similarly, to the E-2 visa USA program,
                “substantial trade” is not defined as a particular fixed or minimum trade
                amount. Although there is no minimum or fixed amount of trade that is
                required, generally it is important that there be a continuous flow of
                several transactions over a period of time. Also, the E1 visa requirements
                mandate that “principal trade” occur between the US and the treaty
                country, that is trade between the treaty country and the United States
                must equal more than 50% of the total trade of the E1 business.
                <br />
                <br />
                The E-2 visa USA program, on the other hand, is specifically reserved for
                investors from treaty countries who wish to invest substantial amount in a
                US enterprise. The program requires there to be an actual capital
                investment and the investor must be actively involved in developing and
                directing the business.
              </p>
            </div>
          </div>
          <Signature />
        </div>
      </div>

      <AbTestingDeck
        title="E-2 Visa Testimonials"
        testimonials={INVESTOR_VISA_IMMIGRATION_TESTIMONIALS}
      />

      <PilarNavigation />
      <CtaDeck />
      <SuccessStoriesDeck />
    </Layout>
  );
}

export default E2Visa;
